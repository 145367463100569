import React, { useState } from 'react';

const EMPTY = 0;
const BLACK = 1;
const WHITE = 2;

const styles = {
  othello: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  status: {
    marginBottom: '16px',
  },
  board: {
    border: '1px solid #999',
  },
  boardRow: {
    display: 'flex',
  },
  square: {
    width: '48px',
    height: '48px',
    border: '1px solid #999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    background: 'none',
    cursor: 'pointer',
  },
  piece: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
  },
  black: {
    backgroundColor: 'black',
  },
  white: {
    backgroundColor: 'white',
    border: '1px solid black',
  },
};

const Othello = () => {
  const [board, setBoard] = useState(() => {
    const initialBoard = Array(8).fill().map(() => Array(8).fill(EMPTY));
    initialBoard[3][3] = WHITE;
    initialBoard[3][4] = BLACK;
    initialBoard[4][3] = BLACK;
    initialBoard[4][4] = WHITE;
    return initialBoard;
  });
  const [currentPlayer, setCurrentPlayer] = useState(BLACK);

  const isValidMove = (row, col) => {
    if (board[row][col] !== EMPTY) return false;

    const directions = [
      [-1, -1], [-1, 0], [-1, 1],
      [0, -1],           [0, 1],
      [1, -1],  [1, 0],  [1, 1]
    ];

    for (const [dx, dy] of directions) {
      let x = row + dx;
      let y = col + dy;
      let foundOpponent = false;

      while (x >= 0 && x < 8 && y >= 0 && y < 8) {
        if (board[x][y] === EMPTY) break;
        if (board[x][y] === currentPlayer) {
          if (foundOpponent) return true;
          break;
        }
        foundOpponent = true;
        x += dx;
        y += dy;
      }
    }

    return false;
  };

  const flipPieces = (row, col) => {
    const newBoard = board.map(row => [...row]);
    const directions = [
      [-1, -1], [-1, 0], [-1, 1],
      [0, -1],           [0, 1],
      [1, -1],  [1, 0],  [1, 1]
    ];

    for (const [dx, dy] of directions) {
      let x = row + dx;
      let y = col + dy;
      const piecesToFlip = [];

      while (x >= 0 && x < 8 && y >= 0 && y < 8) {
        if (board[x][y] === EMPTY) break;
        if (board[x][y] === currentPlayer) {
          for (const [fx, fy] of piecesToFlip) {
            newBoard[fx][fy] = currentPlayer;
          }
          break;
        }
        piecesToFlip.push([x, y]);
        x += dx;
        y += dy;
      }
    }

    newBoard[row][col] = currentPlayer;
    return newBoard;
  };

  const handleClick = (row, col) => {
    if (!isValidMove(row, col)) return;

    const newBoard = flipPieces(row, col);
    setBoard(newBoard);
    setCurrentPlayer(currentPlayer === BLACK ? WHITE : BLACK);
  };

  const renderSquare = (row, col) => {
    const value = board[row][col];
    return (
      <button style={styles.square} onClick={() => handleClick(row, col)}>
        {value === BLACK && <div style={{...styles.piece, ...styles.black}} />}
        {value === WHITE && <div style={{...styles.piece, ...styles.white}} />}
      </button>
    );
  };

  return (
    <div style={styles.othello}>
      <div style={styles.status}>
        {currentPlayer === BLACK ? "黒の番です" : "白の番です"}
      </div>
      <div style={styles.board}>
        {board.map((row, i) => (
          <div key={i} style={styles.boardRow}>
            {row.map((_, j) => (
              <React.Fragment key={j}>
                {renderSquare(i, j)}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Othello;